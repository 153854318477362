import React, {useState} from 'react';

import { IoClose } from 'react-icons/io5';

import "./buttons.scss";

import {clsx} from 'clsx';

import { FiChevronRight } from 'react-icons/fi';
import {IoCloseCircle} from 'react-icons/io5';

export function ButtonBox(params) {
    let buttonClicked = () => {
        if (!params.readOnly && !params.disabled && params.clicked) {
            params.clicked();
        }
    }
    let buttonClearClicked = () => {
        if (!params.disabled && params.cleared) {
            params.cleared();
        }
    }

    return (
        <div className={clsx({"app-buttons": true, "disabled": params.disabled })}>
            {(!!params.selectedItem && !params.readOnly && params.showClear) &&
                <div className={"app-buttons-clear"} onClick={buttonClearClicked}>
                    {/*<IoClose size={"20px"} />*/}
                    {/*<img src={"/close.png"} />*/}
                    <IoCloseCircle size={24} color={"#cacaca"} />
                </div>
            }
            {params.showChevron && !(!!params.selectedItem && !params.readOnly) &&
                <div className={"app-buttons-clear"} onClick={buttonClicked}>
                    {/*<IoClose size={"20px"} />*/}
                    <FiChevronRight />
                </div>
            }
            <div className={"app-logic"} onClick={buttonClicked}>
                {params.customHTML}
            </div>
            {/*<params.customHTML />*/}
        </div>
    )
}
