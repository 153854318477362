import "./button.scss";

function Button({label, disabled, mode, clicked}) {
    let onClick = () => {
        if (!disabled && clicked) {
            clicked();
        }
    };

    return (
        // eslint-disable-next-line
        <a className={"button" + (disabled ? " disabled" : "") + (mode ? " " + mode : "")} onClick={onClick}>
            {label}
        </a>
    )
}

export default Button;
