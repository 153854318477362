import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";
import moment from 'moment-timezone';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

// FIXME: maybe we should move it into config ... but not for now ..
moment.tz.setDefault("Europe/London");

const router = createBrowserRouter([
    {
        path: "/*",
        element: <App/>,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={window.getGlobalConfig().domain}
            scope="read:current_user"
            clientId={window.getGlobalConfig().client_id}
            audience={window.getGlobalConfig().audience}
            redirectUri={window.getGlobalConfig().redirect_uri}
            onRedirectCallback={(state) => {
                window._qParamList = state.list;
            }}
        >
            {/*<App/>*/}
            <RouterProvider router={router} />
        </Auth0Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
