import "./textbox.scss";
import React, {useEffect} from "react";
import {IoCloseCircle} from 'react-icons/io5';

function TextBox(props) {
    let handleChange = (event) => {
        if (props.changed) {
            props.changed(event.target.value)
        }
    }

    let clearClicked = () => {
        if (props.clearClicked) {
            props.clearClicked();
        }
    }

    return (
        <div className="textbox">
            {/*<div className="icon search"></div>*/}
            <input className="textbox-input" type="text"
                   value={props.value}
                   placeholder={props.placeholder ? props.placeholder : ""}
                   onChange={handleChange}
            />
            <div className={"clear-text"} onClick={clearClicked}>
                {/*Clear*/}
                {/*<img src={"/close.png"} />*/}
                <IoCloseCircle size={24} color={"#cacaca"} />
            </div>
        </div>
    )
}

export default TextBox;
