import React, {useEffect, useState} from "react";
import _ from "lodash";
import moment from 'moment-timezone';
import {clsx} from 'clsx';

import {FiChevronLeft} from 'react-icons/fi';
import {FiChevronRight} from 'react-icons/fi';

import {CgChevronRightR, CgChevronRight, CgChevronLeft} from 'react-icons/cg';

import "./weekCalendar.scss";

function changeDayToWeekLimit(day) {
    let firstDayOfWeek = moment.localeData().firstDayOfWeek();

    let nd = day - firstDayOfWeek;
    while (nd > 6) {
        nd -= 7;
    }
    while (nd < 0) {
        nd += 7;
    }

    return nd;
}

function MonthCalendar({
                           slots,
                           consultantSelected,
                           disabled,
                           placeholder,
                           onSlotSelect,
                           ignoreNotInMonth,
                           onLoadMore,
                           canLoadMore,
                           selectedDateExt
                       }) {
    const [selectedDate, setSelectedDate] = useState(null);
    // const [selectedDate, setSelectedDate] = useState(selectedDateExt ? moment(selectedDateExt.when) : null);
    const [selectedSlot, setSelectedSlot] = useState(null);

    const [firstAvailableSlotISOTime, setFirstAvailableSlotISOTime] = useState(moment().toISOString());
    const [additionalMothsToShow, setAdditionalMothsToShow] = useState(0);

    const [firstFreeTime, setFirstFreeTime] = useState(null);
    const [lastFreeTime, setLastFreeTime] = useState(null);

    const [monthSelected, setMonthSelected] = useState(0);

    useEffect(() => {
        if (selectedDateExt) {
            setSelectedDate(moment(selectedDateExt.when));
        } else {
            setSelectedDate(null);
            setMonthSelected(0);
        }
    }, [selectedDateExt]);

    useEffect(() => {
        if (!slots) {
            setSelectedSlot(null);
        }
    }, [slots]);

    useEffect(() => {
        setSelectedSlot(null);
    }, [consultantSelected]);

    const selectDate = daySelected => {
        return () => {
            // if (!daySelected.isInRange || !selectable(daySelected))
            //     return;
            if (!selectable(daySelected))
                return;

            let dateToCheck = daySelected.date;
            let slot = null;
            _.forEach(slots, s => {
                const when = moment(s.when);
                if (when.isSame(dateToCheck, "day")) {
                    slot = s;
                }
            })

            if (slot) {
                setSelectedDate(dateToCheck);
                setSelectedSlot(slot);
                if (onSlotSelect) {
                    // console.log("onSlotSelect", daySelected, slot);
                    onSlotSelect(slot);
                }
            } else {
                setSelectedDate(null);
                setSelectedSlot(null);
                if (onSlotSelect) {
                    onSlotSelect(null);
                }
            }
        }
    }

    const checkDate = (day, dateToCompare) => {
        let dateToCheck = day.date;
        return dateToCheck && dateToCheck.isSame(dateToCompare, "days");
    }

    function changePositionToWeekday(day) {
        let firstDayOfWeek = moment.localeData().firstDayOfWeek();

        let nd = day + firstDayOfWeek;
        while (nd > 6) {
            nd -= 7;
        }
        while (nd < 0) {
            nd += 7;
        }

        return nd;
    }

    let weekDaysShort = moment.weekdaysShort();
    let weekDayToRender = [];
    for (let i = 0; i < weekDaysShort.length; i++) {
        weekDayToRender.push(weekDaysShort[changePositionToWeekday(i)]);
    }

    // let labelLine = null;
    let fromDate = null;
    let toDate = null;
    if (firstAvailableSlotISOTime) {
        // fromDate = moment(firstAvailableSlotISOTime).startOf('week');
        // toDate = moment(firstAvailableSlotISOTime).endOf('week');
        fromDate = moment(firstAvailableSlotISOTime).add(monthSelected, "month").startOf('month');
        toDate = moment(firstAvailableSlotISOTime).add(monthSelected, "month").endOf('month');

        // let m1 = fromDate.format("MMMM");
        // let m2 = toDate.format("MMMM");
        // let yy = toDate.format("YYYY");
        // if (m1 !== m2) {
        //     m1 += "/" + m2;
        // }
        // labelLine = m1 + " " + yy;
    }

    // labelLine = moment(firstAvailableSlotISOTime)
    //     .startOf("month").format("MMMM YYYY");
    // if (placeholder) {
    //     labelLine = moment().format("MMMM YYYY");
    // }

    let getLabelLine = nr => {
        let labelLine = moment(firstAvailableSlotISOTime)
            .startOf("month").add(nr, "month").format("MMMM YYYY");
        if (placeholder) {
            labelLine = moment().format("MMMM YYYY");
        }

        return labelLine;
    };

    let canGoBack = () => {
        if (fromDate && toDate) {
            let status = false;
            _.forEach(slots, s => {
                const when = moment(s.when);
                if (when.isBefore(fromDate, "day")) {
                    status = true;
                }
            })

            return status;
        }
        return false;
    }
    let canGoForward = () => {
        if (fromDate && toDate) {
            let status = false;
            _.forEach(slots, s => {
                const when = moment(s.when);
                if (when.isAfter(toDate, "day")) {
                    status = true;
                }
            })

            return status;
        }
        return false;
    }

    let goBack = () => {
        if (canGoBack()) {
            setFirstAvailableSlotISOTime(moment(firstAvailableSlotISOTime).subtract(1, 'week').toISOString());
        }
    }
    let goForward = () => {
        if (canGoForward()) {
            setFirstAvailableSlotISOTime(moment(firstAvailableSlotISOTime).add(1, 'week').toISOString());
        }
    }

    let getDayNumber = day => {
        if (fromDate && toDate) {
            let dateToCheck = moment(fromDate).add(day, 'days');
            return dateToCheck.format("D");
        }
        return "";
    }

    let isDisabled = day => {
        if (slots && fromDate && toDate) {
            let dateToCheck = moment(fromDate).add(day, 'days');
            let status = true;
            _.forEach(slots, s => {
                const when = moment(s.when);
                if (when.isSame(dateToCheck, "day")) {
                    status = false;
                }
            })

            return status;
        }

        return true;
    }

    let faSlot = moment(firstAvailableSlotISOTime).add(monthSelected, "month");

    let rowsToRender = [];
    let dayOnCalendarStart = changeDayToWeekLimit(moment(faSlot)
        .startOf("month")
        .format("d"));
    let dateRenderStart = moment(moment(faSlot)).startOf("month").subtract(dayOnCalendarStart, "d");
    for (let row = 0; row < 6; row++) {
        for (let weekDay = 0; weekDay < 7; weekDay++) {
            let d = moment(dateRenderStart).add(row * 7 + weekDay, "d");
            rowsToRender.push({
                date: moment(d),
                dateFmt: d.format("D"),
                isInRange: d.startOf("month").isSame(moment(faSlot), "month"),
            });
        }
    }

    let selectable = day => {
        let selectable = false;
        _.forEach(slots, s => {
            // console.log(day.date.format("YYYY-MM-DD"), moment(s.when).format("YYYY-MM-DD"));
            if (day.date.isSame(moment(s.when), "day")) {
                selectable = true;
            }
        })
        return selectable;
    }

    useEffect(() => {
        let firstFreeTime = null;
        _.forEach(slots, s => {
            const when = moment(s.when);
            if (firstFreeTime === null || when.isBefore(firstFreeTime)) {
                firstFreeTime = when;
            }
        })

        let lastFreeTime = null;
        _.forEach(slots, s => {
            const when = moment(s.when);
            if (lastFreeTime === null || when.isAfter(lastFreeTime)) {
                lastFreeTime = when;
            }
        })
        // console.log('firstFreeTime, lastFreeTime', firstFreeTime, lastFreeTime);
        // console.log('firstFreeTime, lastFreeTime', firstFreeTime.month(), lastFreeTime.month());

        if (firstFreeTime && lastFreeTime) {
            setAdditionalMothsToShow(lastFreeTime.month() - firstFreeTime.month());
            // setAdditionalMothsToShow(3);

            setFirstFreeTime(firstFreeTime);
            setLastFreeTime(lastFreeTime);
        }

        if (firstFreeTime) {
            setFirstAvailableSlotISOTime(firstFreeTime.toISOString());
            // setFirstAvailableSlotISOTime(firstFreeTime.startOf('month').toISOString());
        } else {
            setFirstAvailableSlotISOTime(moment().startOf('month').toISOString());
            setAdditionalMothsToShow(0);

            setFirstFreeTime(null);
            setLastFreeTime(null);
        }
        /*
        if (firstFreeTime) {
            setFirstAvailableSlotISOTime(firstFreeTime.startOf('month').toISOString());
        } else {
            setFirstAvailableSlotISOTime(moment().startOf('month').toISOString());
        }
        */
    }, [slots]);

    return (
        <div className={clsx({"week-calendar": true, "placeholder": placeholder === true})}>
            <div>
                <div className="top-label">
                    <div className={clsx("go-left", canGoBack() ? "enabled" : "")}>
                        <div className={"go-btn"} onClick={() => {
                            if (canGoBack()) {
                                setMonthSelected(monthSelected - 1);
                            }
                        }}>
                            <CgChevronLeft size={24}/>
                        </div>
                    </div>
                    <div className="label">
                        {getLabelLine(monthSelected)}
                    </div>
                    <div className={clsx("go-right", canGoForward() ? "enabled" : "")}>
                        <div className={"go-btn"} onClick={() => {
                            if (canGoForward()) {
                                setMonthSelected(monthSelected + 1);
                            }
                        }}>
                            <CgChevronRight size={24}/>
                        </div>
                    </div>
                </div>

                <table cellPadding="0" cellSpacing="0">
                    <tbody>

                    <tr>
                        {weekDayToRender.map((weekDay, day) => (
                            <th key={weekDay}
                                className={clsx({"week-day": true, "disabled": true})}>{weekDay}</th>
                        ))}
                    </tr>

                    {Array(6).fill(0).map((num, row) => (
                        <tr key={"row" + row} className={"multi-column-td"}>
                            {Array(7).fill(0).map((num, day) => (
                                <td key={"pos" + (row * 7 + day)}>
                                    {
                                        rowsToRender[row * 7 + day].isInRange &&
                                        <div className={clsx({
                                            "column": true,
                                            "selected": checkDate(rowsToRender[row * 7 + day], selectedDate),
                                            "disabled": !selectable(rowsToRender[row * 7 + day])
                                        })}
                                             onClick={selectDate(rowsToRender[row * 7 + day])}
                                        >
                                            {rowsToRender[row * 7 + day].dateFmt}
                                        </div>
                                    }
                                </td>
                            ))}
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>

                <div className={clsx("show-more-appts", canLoadMore ? "" : "disabled")}
                     onClick={() => {
                         if (canLoadMore && onLoadMore) {
                             onLoadMore();
                         }
                     }}
                >
                    <span className={"small-text"}>Show more appointments</span>
                </div>
        </div>
    )
}

export default MonthCalendar;
